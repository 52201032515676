<template>
  <div>
    <div v-if="isLoading" class="my-14">
      <v-row justify="center" align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </div>
    <v-card v-else class="card my-5">
      <v-card-text class="text-description">
        <v-row class="card-title col-12" no-gutters align="center">
          {{ product.name }}
          <v-spacer></v-spacer>
          <v-btn v-if="isTrending" rounded text outlined small> 🔥 </v-btn>
          <v-btn v-if="showChangePlan" text small>
            <span
              class="text-underline text-capitalize"
              @click="$router.push({ name: 'ChoosePlan' })"
            >
              change plan
            </span>
          </v-btn>
        </v-row>
        <v-row align="center">
          <v-col cols="auto" v-if="product.percentage">
            <v-btn fab text outlined class="font-weight-bold">
              {{ product.percentage }}%
            </v-btn>
          </v-col>
          <v-col
            :class="{
              'font-weight-bold': isTrending,
            }"
          >
            <v-row v-if="product.description">{{ product.description }}</v-row>
            <v-row align="center">
              <v-icon small v-if="product.percentage"> mdi-plus </v-icon>
              <v-col cols="auto" :class="{ 'pa-0': product.percentage }">
                <v-img
                  height="30"
                  contain
                  :src="require('@/assets/trees-icon.svg')"
                />
              </v-col>
              <v-col> {{ product.treesNumber }} trees planted a month </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col v-if="getMonthlyCost && product">
            Total per month:
            <span class="font-weight-bold"> £{{ totalPrice }} </span>
          </v-col>
          <v-col cols="auto" v-if="showCTAButton">
            <v-btn
              v-if="!userAlreadySubscribed"
              class="cta-button"
              dark
              rounded
              large
              depressed
              @click="choosePlan"
              >Start now</v-btn
            >
            <v-btn
              v-if="userAlreadySubscribed && !isCurrentPlan"
              class="cta-button"
              dark
              rounded
              large
              depressed
              @click="updatePlan"
              :loading="isLoading"
              :disabled="isLoading"
              >update plan</v-btn
            >
            <v-btn
              v-if="userAlreadySubscribed && isCurrentPlan"
              color="grey"
              dark
              rounded
              large
              depressed
              :loading="isLoading"
              :disabled="isLoading"
              >current plan</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  created() {
    this.fetchUserFSDocument();
  },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    product: {
      required: true,
    },

    showCTAButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    showChangePlan: {
      type: Boolean,
      default: false,
      required: false,
    },
    userAlreadySubscribed: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getUserFSDocument", "getMonthlyCost"]),
    isTrending() {
      return this.product.percentage == 100;
    },
    totalPrice() {
      return parseFloat(
        this.getMonthlyCost * (this.product.percentage / 100)
      ).toFixed(2);
    },
    isCurrentPlan() {
      return (
        parseFloat(this.getUserFSDocument.subscription.percentage) ==
        parseFloat(this.product.percentage)
      );
    },
  },
  methods: {
    ...mapMutations([
      "setChosenPlan",
      "setErrorSnackbarMessage",
      "showFeedbackDialogData",
    ]),
    ...mapActions(["sendUpdatePlan", "fetchUserFSDocument"]),
    choosePlan() {
      // cache the chosen plan for future use
      this.setChosenPlan({ ...this.product, total: this.totalPrice });
      // redirect to next page, if user not signed up yet redirect him to sign up page
      if (this.getCurrentUser) {
        this.$router.push({ name: "ChoosePaymentMethod" });
      } else {
        this.$router.push({ name: "SignUp" });
      }
    },
    async updatePlan() {
      this.isLoading = true;
      try {
        // cache the chosen plan for future use
        this.setChosenPlan({ ...this.product, total: this.totalPrice });

        // prepare the request body
        const requestBody = {
          product: this.product,
          total: this.totalPrice,
          uid: this.getCurrentUser.uid,
        };
        // send the product with all it's data to the "sendUpdatePlan" action
        await this.sendUpdatePlan(requestBody);
        // fetch the user Document again, with the updated data (plan)
        await this.fetchUserFSDocument();
        // show success message
        this.showFeedbackDialogData({
          title: "You’ve updated your plan",
          description: "Planet Earth thanks you 🌍",
        });
        // redirect to customer dashboard
        this.$router.push({ name: "CustomerDashboard" });
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style  scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(91, 91, 91, 0.16) !important;
  border-radius: 16px !important;
}
.card-title {
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
}
.text-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
}
</style>